import React, {useEffect, useRef, useState} from "react";
import {
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter,
    TextFilterProps
} from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { TableEmptyState, TableNoMatchState } from "../commons/common-components";
import { RagaAvailabilities, RagaAvailability } from "../../model/raga-availability";
import { LIST_RAGA_AVAILABILITIES_RESPONSE_SNAPSHOT } from "./list-raga-availabilities-response-snapshot";

const COLUMN_DEFINITIONS = [
    {
        id: "buyingCustomerCountryCode",
        header: "Buying customer country code",
        cell: (item: RagaAvailability) => item.buyingCustomerCountryCode,
        width: 100,
        sortingField: "buyingCustomerCountryCode",
    },
    {
        id: "product",
        header: "Product",
        cell: (item: RagaAvailability) => item.product,
        width: 100,
        sortingField: "product",
    },
    {
        id: "term",
        header: "Term",
        cell: (item: RagaAvailability) => item.term,
        width: 100,
        sortingField: "term",
    },
    {
        id: "ragaEnabled",
        header: "Raga enabled in",
        cell: (item: RagaAvailability) => item.ragaEnabled,
        width: 100
    }
]

const RagaAvailabilityTable = () => {
    const [allItems, setAllItems] = useState<RagaAvailabilities>();
    const [loading, setLoading] = useState<boolean>(false);
    const [fetchError, setFetchError] = useState<string | null>(null);
    const textFilterRef = useRef<TextFilterProps.Ref>(null);

    const ragaAvailabilities = LIST_RAGA_AVAILABILITIES_RESPONSE_SNAPSHOT.ragaAvailabilities;

    useEffect(() => {
        textFilterRef.current?.focus();
    }, []);

    const loadItems = async () => {
        setLoading(true);
        setFetchError(null);
        try {
            // TODO: fetch data from API
            await new Promise((resolve) => setTimeout(resolve, 1000));
            setAllItems(ragaAvailabilities);
        } catch (error: unknown) {
            setFetchError(error instanceof Error ? error.message : String(error));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadItems();
    }, []);

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        allItems || [],
        {
            filtering: {
                fields: ['buyingCustomerCountryCode'],
                empty: (<TableEmptyState resourceName="Raga availability" />),
                noMatch: (<TableNoMatchState onClearFilter={() => actions.setFiltering('')} />)
            },
            pagination: {
                pageSize: 100
            },
            sorting: {
                defaultState: {
                    sortingColumn: COLUMN_DEFINITIONS[0],
                }
            },
            selection: {}
        }
    );
    const selectedItems = collectionProps.selectedItems!;

    return (
        <SpaceBetween size="m">
            <Table
                {...collectionProps}
                wrapLines={false}
                loading={loading}
                loadingText="Loading Raga availabilities"
                stickyHeaderVerticalOffset={1}
                variant='full-page'
                header={
                    <Header
                        variant="awsui-h1-sticky"
                        counter={
                            allItems &&
                            (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
                        }
                        actions={null}
                    >
                        Raga availabilities
                    </Header>
                }
                columnDefinitions={COLUMN_DEFINITIONS}
                items={items}
                pagination={<Pagination {...paginationProps} />}
                filter={
                    <TextFilter
                        {...filterProps}
                        ref={textFilterRef}
                        countText={`${filteredItemsCount} match(es)`}
                        filteringPlaceholder="Filter by country code"
                    />
                }
            />
        </SpaceBetween>
    );
}

export default RagaAvailabilityTable;
