import { ListRagaAvailabilitiesResponse } from '../../model/raga-availability';

export const LIST_RAGA_AVAILABILITIES_RESPONSE_SNAPSHOT: ListRagaAvailabilitiesResponse = {
  ragaAvailabilities: [
    {
      term: "ANNUAL",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "CL",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "ID",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "EG",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "IE",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "IN",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "PL",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "UY",
    },
    {
      term: "ANNUAL",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "TH",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "IT",
    },
    {
      term: "MONTHLY",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "ZA",
    },
    {
      term: "MONTHLY",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "TH",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "SG",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "AU",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "TH",
    },
    {
      term: "ANNUAL",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "PH",
    },
    {
      term: "MONTHLY",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "AR",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "PT",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "TR",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "BR",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "IT",
    },
    {
      term: "MONTHLY",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "IN",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "EG",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "AU",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "ES",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "MX",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "PA",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "ZA",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "AU",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "PY",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "DE",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "US",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "GT",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "IT",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "CR",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "ES",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "EG",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "AE",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "BO",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "SV",
    },
    {
      term: "MONTHLY",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "CL",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "US",
    },
    {
      term: "MONTHLY",
      product: "KINDLE",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "AU",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "IN",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "PT",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "PL",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "BR",
    },
    {
      term: "MONTHLY",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "PY",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "AE",
    },
    {
      term: "ANNUAL",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "MY",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "CH",
    },
    {
      term: "ANNUAL",
      product: "VIDEO",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "AR",
    },
    {
      term: "MONTHLY",
      product: "PV_AD_FREE",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "FR",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "SE",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "JP",
    },
    {
      term: "MONTHLY",
      product: "PVGO",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "MX",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "GB",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "GB",
    },
    {
      term: "ANNUAL",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "PH",
    },
    {
      term: "MONTHLY",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "BR",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "DO",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "AR",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "SA",
    },
    {
      term: "MONTHLY",
      product: "AVCHANNELS",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "FR",
    },
    {
      term: "MONTHLY",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "ID",
    },
    {
      term: "MONTHLY",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "NG",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "BO",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "AT",
    },
    {
      term: "MONTHLY",
      product: "AUDIBLE",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "IT",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "PL",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "PE",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "NI",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "DE",
    },
    {
      term: "MONTHLY",
      product: "KINDLE",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "GB",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "PA",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "PT",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "FR",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "CO",
    },
    {
      term: "MONTHLY",
      product: "AUDIBLE",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "US",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "MY",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "SG",
    },
    {
      term: "MONTHLY",
      product: "KINDLE",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "PH",
    },
    {
      term: "ANNUAL",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "ZA",
    },
    {
      term: "MONTHLY",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "CO",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "TR",
    },
    {
      term: "ANNUAL",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "IN",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "IT",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "JP",
    },
    {
      term: "MONTHLY",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "PH",
    },
    {
      term: "ANNUAL",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "ID",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "FR",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "IE",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "EC",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "CA",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "US",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "ES",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "CL",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "GT",
    },
    {
      term: "MONTHLY",
      product: "PV_AD_FREE",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "ES",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "HN",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "GB",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "DE",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "ES",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "NZ",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "HN",
    },
    {
      term: "MONTHLY",
      product: "AUDIBLE",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "ES",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "IN",
    },
    {
      term: "MONTHLY",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "HN",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "GB",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "NG",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "PH",
    },
    {
      term: "ANNUAL",
      product: "PVGO",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "ID",
    },
    {
      term: "ANNUAL",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "TH",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "DK",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "CA",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "CO",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "NO",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "FR",
    },
    {
      term: "MONTHLY",
      product: "KINDLE",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "BR",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "NI",
    },
    {
      term: "MONTHLY",
      product: "MUSIC",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "JP",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "SA",
    },
    {
      term: "MONTHLY",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "NL",
    },
    {
      term: "MONTHLY",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "UY",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "PY",
    },
    {
      term: "MONTHLY",
      product: "KINDLE",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "US",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "uat",
      buyingCustomerCountryCode: "BR",
    },
    {
      term: "MONTHLY",
      product: "VIDEO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "SV",
    },
    {
      term: "ANNUAL",
      product: "PRIME",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "MX",
    },
    {
      term: "MONTHLY",
      product: "PVGO",
      ragaEnabled: "prod",
      buyingCustomerCountryCode: "GT",
    },
  ],
};
